a:link {
  color:#0ae7ef
}

a:visited {
  color:#ff7300
}

a:hover {
  color: #0077ff;
}

.App {
  background-color: #282c34;
  color: white;
  min-height: 100vh;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
  margin-bottom: 75px;
  margin-top: 130px
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2.0s linear;
  }
}

.Error-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  animation: Blinking-background 0.5s infinite;
}

.Error-text {
  font-size: 12px;
  color: #fa0000;
}

.App-header {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: calc(50px + 2vmin);
}

.Homepage {
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Bracket {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.Poll {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.User {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.Login {
  background-color: #282c34;
  color: white;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Navigation {
  background-color: #282c34;
  color: white;
  font-size: calc(5px + 2vmin);
  display: flex;
}

.nav-list {
  list-style: none;
  display:flex;
  justify-content:center;
  flex-wrap: wrap;
  gap: 15px;
}

img {
  max-width: 100%;
  height: auto;
}


/************* Animations ***********************/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Blinking-background{
  0%		{ background-color: #a6c010;}
  25%		{ background-color: #000000;}
  50%		{ background-color: #ef5e0a;}
  75%		{ background-color: #00661a;}
  100%	{ background-color: #fa0000;}
}
